import React, {Component} from "react"
import ErrorImage from '../ErrorImage';

class StatusView extends Component {
    constructor() {
        super();
        this.state = {
            hasInternet: true,
            karren: [],
        };

        // this.checkServerHeartbeat = this.checkServerHeartbeat.bind(this);
        this.fetchStatusinfo = this.fetchStatusinfo.bind(this);
        this.statusToText = this.statusToText.bind(this);
    }
    
    componentDidMount() {
        this.fetchStatusinfo();
        // this.checkServerHeartbeat();
    }

    // checkServerHeartbeat() {
    //     fetch("https://intranet.foubert.eu/api/frontend.php?action=get_heartbeat",{
    //         method: 'POST', 
    //     }).then(() => {
    //         this.setState({
    //             hasInternet: true,
    //         });
    //         setTimeout(this.fetchStatusinfo, 1000);
    //     }).catch(() => {
    //         this.setState({
    //             hasInternet: false,
    //         });
    //         setTimeout(this.checkServerHeartbeat, 1000);
    //     });
    // }

    fetchStatusinfo() {
        const url = process.env.REACT_APP_ICECORP_API_HREF+"v1/icecreamvanmarkerdata?company_id="+process.env.REACT_APP_COMPANY_ID;
        fetch(url)
        .then(response => response.json())
        .then(data => {
            const karren = data.data;
            console.log(karren);

            this.setState({
                karren: karren,
            });

            setTimeout(this.fetchStatusinfo, 1000);
        })
        .catch(() => {
            // setTimeout(this.checkServerHeartbeat, 1000);
            setTimeout(this.fetchStatusinfo, 1000);
        });
    }

    statusToText(kar) {
        switch (kar.status) {
            case "STARTUP":
                return <>Vertrekt zodadelijk{(kar.status_payload?.scheduled_at_name ? <> richting {kar.status_payload?.scheduled_at_name}</> : null)}</>;
                break;
            case "EN_ROUTE_TO_ZONE":
                return <>Onderweg{(kar.status_payload?.scheduled_at_name ? <> naar {kar.status_payload?.scheduled_at_name}</> : <> naar zone</>)}</>;
                break;
            case "IN_ZONE":
                return <>Aan het rijden{(kar.status_payload?.scheduled_at_name ? <> in {kar.status_payload?.scheduled_at_name}</> : null)}</>;
                break;
            case "EN_ROUTE_TO_DEPOT":
                return <>Uitverkocht</>;
                break;
            case "CLEANUP":
                return <>Uitverkocht</>;
                break;
            case "FINISHED":
                return <>Uitverkocht</>;
                break;
            case "SCHEDULED":
                return <>Gepland{(kar.status_payload?.scheduled_at_name ? <> in {kar.status_payload?.scheduled_at_name}</> : null)}</>;
                break;
            case "NOT_SCHEDULED":
                return <>Niet gepland vandaag</>;
                break;
            case "EN_ROUTE_TO_ICECREAM_VAN_RESERVATION":
                return <>Onderweg naar reservatie</>;
                break;
            case "AT_ICECREAM_VAN_RESERVATION":
                return <>Op reservatie</>;
                break;
            case "ON_BREAK":
                return <>In pauze</>;
                break;
            default:
                return <></>;
                break;
        }
    }

    render() {
        if (this.state.hasInternet) {
            if (this.state.karren.length > 0) {
                const overzicht = this.state.karren.map(kar => <tr>
                    <td style={{paddingRight: "20px"}}><img src={(kar.available ? "/images/tracker/icon_kar_available.png" : "/images/tracker/icon_kar_notavailable.png")} style={{width: "15px", marginRight:"5px"}}/><b>{kar.title}</b></td>
                    <td>{kar.available ? "Beschikbaar: " : "Niet beschikbaar: "} {this.statusToText(kar)}</td>
                </tr>);
                return (
                    <div className="content container">
                        <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                                </div>
                            </div>
                            {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a> */}
                        </div>
                        <h2>Scoop 'n ride status</h2>
                        <p>Dit is de huidige statussen van onze IJsfietsen:</p>
                        <ul>
                            <table>
                                {overzicht}
                            </table>
                        </ul>
                    </div>
                )
            } else {
                return (
                    <div className="content container">
                        <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                                </div>
                            </div>
                            {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a> */}
                        </div>
                        <h2>Scoop 'n ride status</h2>
                        <p>De statussen wordt opgehaald...</p>
                    </div>
                )
            }
        } else {
            return (
                <main>
                    <div className="content">
                        <ErrorImage text="Je bent offline" subtext="Sommige onderdelen van de app werken mogelijk niet"/>
                    </div>
                </main>
            )
        }
    }
}

export default StatusView