import React, {Component} from "react"

class AboutView extends Component {
    render() {
        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                <h2>Scoop 'n Ride – Pinguino IJs, Overal en Altijd!</h2>
                <p>Bij Pinguino draait alles om het creëren van kleine geluksmomenten, en met Scoop 'n Ride brengen we dat geluk tot bij jou! Met dit nieuwe concept maken we onze unieke, ambachtelijke smaken nog toegankelijker – of je nu in Leuven rondwandelt of een speciaal event organiseert.</p>
                <p>Onze ijsfiets is gevuld met vers, met liefde gemaakt ijs, bereid met zorgvuldig geselecteerde ingrediënten en huisgemaakte toppings. En met ons nieuwe concept brengen we de Pinguino-smaakbeleving naar de straten van Leuven en evenementen, zodat iedereen kan genieten van een ambachtelijke schep vol vreugde.</p>
                <p>En goed nieuws: er is een ijsje voor iedereen! Vegan? Suikervrij? Geen probleem. Bij ons vind je niet alleen enkele van onze signature smaken, maar ook de onmisbare klassiekers die iedereen kent en liefheeft.</p>
                <p>Boek onze Scoop 'n Ride!</p>
                <p>Op zoek naar iets bijzonders voor je feest, bedrijfsevent of een speciale gelegenheid? Wij brengen niet alleen overheerlijk ijs, maar ook een warme, gezellige sfeer waar iedereen zich welkom voelt.</p>
                <p>➡ <a href="https://www.instagram.com/pinguino_gelato/">Volg ons op Instagram</a> en ontdek waar je ons binnenkort kunt vinden!</p>            
                </div>
        )
    }
}

export default AboutView