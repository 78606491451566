import React, {Component} from "react"

class ReserverenView extends Component {
    render() {
        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/reserveren/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                <h2>Reserveren</h2>
                <p><b>Wat is het verschil tussen een reservatie en een oproep?</b></p>
                <p>Wanneer u een IJsfiets reserveert op voorhand, bent u zeker dat we gaan langskomen op uw locatie. Wanneer u een oproep plaatst, heeft u kans dat we langskomen als onze medewerkers in de buurt zijn & tijd hebben (dus als ze naar een reservatie moeten kan dit niet).</p>
                <p><b>Hoe moet ik een IJsfiets reserveren?</b></p>
                <p>Simpel, klik hieronder op "Reserveer een IJsfiets!" en vul ons online formulier in. U ontvangt onmiddellijk een offerte en kan deze IJsfiets dan aanvragen. We controleren wel eerst manueel of we uw reservatie kunnen inplannen in onze planning. Wanneer dat is gebeurd ontvangt u een bevestiging via mail en sms. 95% van de aanvragen kunnen we aanvaarden.</p>
                <div class="text-center" style={{margin: "2rem"}}>
                    <a href="https://reservatie.pinguino.be" target="_blank" className="btn btn-primary btn-rounded">Reserveer een IJsfiets!</a>
                </div>
            </div>
        )
    }
}

export default ReserverenView