import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import { dontMakeCallOnOpen, setLegitCall, setAvailableNearby } from "../../Redux/TrackerActions";
import { throwStatement } from "@babel/types";
import {store} from "../../App";
import { connect } from 'react-redux';
import { setToast, setDialog, clearDialog, setLoader, clearLoader, showScreenBlocker, hideScreenBlocker } from "../../Redux/OverlayActions";

function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
}

function deg2rad(deg) {
    return deg * (Math.PI/180)
}

class CombinedButton extends Component {
    constructor() {
        super();
        this.state = { 
            redirectToCall: false,
            redirectToAddress: false,

            showLocationFailedDialog: false,
            showLocationDeniedDialog: false,

            oproepGeplaatst: false,
        };

        this.handleClick = this.handleClick.bind(this);

        this.checkPermissionLocation = this.checkPermissionLocation.bind(this);

        this.preRequestPermissionLocation = this.preRequestPermissionLocation.bind(this);
        this.handlePreRequestPermissionLocationResponse = this.handlePreRequestPermissionLocationResponse.bind(this);

        this.requestLocation = this.requestLocation.bind(this);
        this.handleLocationRecieved = this.handleLocationRecieved.bind(this);
        this.handleLocationError = this.handleLocationError.bind(this);
        this.handleLocationDenied = this.handleLocationDenied.bind(this);
        this.handleLocationFailed = this.handleLocationFailed.bind(this);

        this.checkGeopositionAgainstStoredPosition = this.checkGeopositionAgainstStoredPosition.bind(this);

        this.makeLocationTranslation = this.makeLocationTranslation.bind(this);
        this.handleLocationTranslationFailed = this.handleLocationTranslationFailed.bind(this);
        this.setAdresStorage = this.setAdresStorage.bind(this);
        this.isStoredAddressValid = this.isStoredAddressValid.bind(this);

        this.checkKarrenInDeBuurt = this.checkKarrenInDeBuurt.bind(this);
        this.handleAvailableNearby = this.handleAvailableNearby.bind(this);
        this.handleNoAvailableNearby = this.handleNoAvailableNearby.bind(this);
        this.handleBlockTooFarAway = this.handleBlockTooFarAway.bind(this);
    }

    componentDidMount() {
        const date = new Date();
        const now = date.getTime();
        const lastCallTime =  window.localStorage.getItem('lastCallTime') || 0;

        if (lastCallTime > now-(1000 * 60 * 60)) {
            this.setState({
                oproepGeplaatst: true,
            });
        }

        if (this.props.makeCallOnOpen) {
            this.handleClick(true);
        }
        store.dispatch(dontMakeCallOnOpen());
    }

    handleClick(skipLocationPopup = false) {
        this.props.handleMapClick();

        if (this.state.oproepGeplaatst) {
            store.dispatch(setToast({
                message: "Je oproep is geplaatst!",
            }));
        } else {
            this.checkPermissionLocation(skipLocationPopup);
        }
    }

    checkPermissionLocation(skipLocationPopup = false) {
        const that = this;
        navigator.permissions && navigator.permissions.query({name: 'geolocation'}).then(function(PermissionStatus) {
            if('granted' === PermissionStatus.state) {
                //Location permission granted
                that.requestLocation();
            } else if('denied' === PermissionStatus.state) {
                //Location permission denied
                that.checkGeopositionAgainstStoredPosition(null, null);
            } else {
                //Location permission unknown
                if (skipLocationPopup) {
                    that.checkGeopositionAgainstStoredPosition(null, null);
                } else {
                    that.preRequestPermissionLocation();   
                }
            }
        });
        if (!navigator.permissions) {
            //This browser does not have the permissions API
            //Fallback to localstorage polyfill
            const geolocationState = (localStorage.getItem('geolocation') ?? '');
            if('granted' === geolocationState) {
                //Location permission granted
                that.requestLocation();
            } else if('denied' === geolocationState) {
                //Location permission denied
                that.checkGeopositionAgainstStoredPosition(null, null);
            } else {
                //Location permission unknown
                if (skipLocationPopup) {
                    that.checkGeopositionAgainstStoredPosition(null, null);
                } else {
                    that.preRequestPermissionLocation();   
                }
            }
        }
    }

    preRequestPermissionLocation() {
        const that = this;
        store.dispatch(setDialog({
            title: "Wil je jouw locatie automatisch invullen?",
            message: "Als je toegang tot jouw locatie toestaat, kunnen we je adres automatisch invullen.",
            btn1Text: "Vul locatie automatisch in",
            btn1Title: "accept",
            btn1Theme: "primary",
            btn2Text: "Liever niet",
            btn2Title: "deny",
            response: that.handlePreRequestPermissionLocationResponse,
        }));
    }

    handlePreRequestPermissionLocationResponse(response) {
        store.dispatch(clearDialog());
        if (response == "accept") {
            this.requestLocation();
        } else if (response == "deny" || response == "dismiss") {
            // this.handleLocationDenied();
            store.dispatch(clearDialog());
            this.checkGeopositionAgainstStoredPosition(null, null);
        }
    }

    requestLocation() {
        store.dispatch(showScreenBlocker());
        navigator.geolocation.getCurrentPosition((geoposition) => {this.handleLocationRecieved(geoposition);}, (error) => {this.handleLocationError(error);});
    }

    handleLocationRecieved(geoposition) {
        store.dispatch(hideScreenBlocker());
        localStorage.setItem('geolocation', "granted");
        this.checkGeopositionAgainstStoredPosition(geoposition.coords.latitude, geoposition.coords.longitude);
    }

    handleLocationError(error) {
        store.dispatch(hideScreenBlocker());
        console.log(error);
        if (error.code == 1) {
            this.handleLocationDenied();
        } else {
            this.handleLocationFailed();
        }
    }

    handleLocationDenied() {
        localStorage.setItem('geolocation', "denied");
        const that = this;
        store.dispatch(setDialog({
            title: "Je locatie kon niet worden bepaald",
            message: "Je gaf geen toestemming om je adres automatisch te bepalen. Je zal je adres dus manueel moeten invullen.",
            theme: "error",
            btn1Text: "Ok",
            btn1Theme: "primary",
            response: () => {store.dispatch(clearDialog()); that.checkGeopositionAgainstStoredPosition(null, null)},
        }));
    }

    handleLocationFailed() {
        const that = this;
        store.dispatch(setDialog({
            title: "Je locatie kon niet worden bepaald",
            message: "Er ging iets mis tijdens het bepalen van je adres. Je zal je adres dus mogelijks manueel moeten invullen.",
            btn1Text: "Ok",
            btn1Theme: "primary",
            response: () => {store.dispatch(clearDialog()); that.checkGeopositionAgainstStoredPosition(null, null)},
        }));
    }

    checkGeopositionAgainstStoredPosition(lat, long) {
        if (!lat || !long) {
            //No GPS-latlong available
            const storedGpsLat = localStorage.getItem('gpslat') || null;
            const storedGpsLong = localStorage.getItem('gpslong') || null;
            if (!storedGpsLat || !storedGpsLong) {
                const storedLat = localStorage.getItem('latitude') || null;
                const storedLong = localStorage.getItem('longitude') || null;
                if (!storedLat || !storedLong) {
                    this.setState({
                        redirectToAddress: true,
                    });
                } else {
                    if (this.isStoredAddressValid()) {
                        this.checkKarrenInDeBuurt();
                    } else {
                        store.dispatch(setToast({
                            message: "Je adres is niet volledig juist. Gelieve je adres aan te vullen",
                            theme: "error"
                        }));
                        this.setState({
                            redirectToAddress: true,
                        });
                    }
                }
            } else {
                if (this.isStoredAddressValid()) {
                    this.checkKarrenInDeBuurt();
                } else {
                    store.dispatch(setToast({
                        message: "Je adres is niet volledig juist. Gelieve je adres aan te vullen",
                        theme: "error"
                    }));
                    this.setState({
                        redirectToAddress: true,
                    });
                }
            }
        } else {
            //GPS returned latlong
            const storedGpsLat = localStorage.getItem('gpslat') || null;
            const storedGpsLong = localStorage.getItem('gpslong') || null;
            if (!storedGpsLat || !storedGpsLong) {
                this.makeLocationTranslation(lat, long);
            } else {
                const dist = getDistanceFromLatLonInKm(lat, long, storedGpsLat, storedGpsLong);
                if (dist < 0.5) {
                    if (this.isStoredAddressValid()) {
                        this.checkKarrenInDeBuurt();
                    } else {
                        store.dispatch(setToast({
                            message: "Je adres is niet volledig juist. Gelieve je adres aan te vullen",
                            theme: "error"
                        }));
                        this.setState({
                            redirectToAddress: true,
                        });
                    }
                } else {
                    this.makeLocationTranslation(lat, long);
                }
            }
        }
    }

    isStoredAddressValid() {
        return (window.localStorage.getItem('straat') != "" && window.localStorage.getItem('nr') != "" && window.localStorage.getItem('gemeente') != "" && window.localStorage.getItem('postcode') != "" && window.localStorage.getItem('land') != "" && window.localStorage.getItem('placeid') != "");
    }

    makeLocationTranslation(gpslat, gpslong) {
        store.dispatch(setLoader({
            title: "Even geduld...",
            message: "We vragen je locatie op",
        }));

        let formData = new FormData();
        formData.append('latitude', gpslat);
        formData.append('longitude', gpslong);

        fetch(process.env.REACT_APP_API_HREF+"legacycopy.php?action=get_adrestranslation",{
            method: 'POST', 
            body: formData,
        }).then(function(response) {
            return response.json();
        }).then(data => {
            console.log(data);
            if (data.status == 200) {
                store.dispatch(clearLoader());

                const result = data.result;
                this.setAdresStorage(gpslat, gpslong, result.adres, result.latitude, result.longitude, result.Straat, result.Nr, result.Gemeente, result.Postcode, result.Land, result.place_id);
                this.checkKarrenInDeBuurt();
            } else {
                this.handleLocationTranslationFailed();
            }
        }).catch(error => {
            console.log(error);
            this.handleLocationTranslationFailed();
        });
    }

    handleLocationTranslationFailed() {
        store.dispatch(clearLoader());

        const that = this;
        store.dispatch(setDialog({
            title: "Je locatie kon niet worden bepaald",
            message: "Er ging iets mis tijdens het bepalen van je adres. Je zal je adres dus mogelijks manueel moeten invullen.",
            btn1Text: "Ok",
            btn1Theme: "primary",
            response: () => {store.dispatch(clearDialog()); that.checkGeopositionAgainstStoredPosition(null, null)},
        }));
    }

    setAdresStorage(gpslat, gpslong, adres, latitude, longitude, straat, nr, gemeente, postcode, land, place_id) {
        window.localStorage.setItem('gpslat', gpslat);
        window.localStorage.setItem('gpslong', gpslong);
        window.localStorage.setItem('adres', adres);
        window.localStorage.setItem('latitude', latitude);
        window.localStorage.setItem('longitude', longitude);
        window.localStorage.setItem('straat', straat);
        window.localStorage.setItem('nr', nr);
        window.localStorage.setItem('gemeente', gemeente);
        window.localStorage.setItem('postcode', postcode);
        window.localStorage.setItem('land', land);
        window.localStorage.setItem('placeid', place_id);
    }

    checkKarrenInDeBuurt() {
        store.dispatch(setLoader({
            title: "Even geduld...",
            message: "We kijken of er IJsfietsen in jouw buurt zijn",
        }));

        const latitude = localStorage.getItem('latitude') || 0;
        const longitude = localStorage.getItem('longitude') || 0;

        const url = process.env.REACT_APP_ICECORP_API_HREF+"v1/icecreamvanmarkerdata?company_id="+process.env.REACT_APP_COMPANY_ID;
        fetch(url)
        .then(response => response.json())
        .then(data => {
            const karren = data.data;
            let availableNearby = false;
            console.log(process.env.REACT_APP_DO_BLOCK_TOO_FAR_AWAY);
            let blockTooFarAway = ((process.env.REACT_APP_DO_BLOCK_TOO_FAR_AWAY == 1) ? true : false);
            console.log(blockTooFarAway);

            karren.forEach(kar => {
                const distanceFromLatLonInKm = getDistanceFromLatLonInKm(kar.latitude, kar.longitude, latitude, longitude);
                if (kar.available && distanceFromLatLonInKm <= (process.env.REACT_APP_THRESHOLD_FOR_BLOCK_TOO_FAR_AWAY/1000)) {
                    blockTooFarAway = false;
                }

                if (kar.available && distanceFromLatLonInKm <= (process.env.REACT_APP_THRESHOLD_FOR_CLOSE/1000)) {
                    availableNearby = true;
                }
            });

            store.dispatch(clearLoader());
            if (blockTooFarAway) {
                this.handleBlockTooFarAway();
            } else if (availableNearby) {
                this.handleAvailableNearby();
            } else {
                this.handleNoAvailableNearby();
            }
        })
        .catch(() => {
            store.dispatch(clearLoader());
            store.dispatch(setDialog({
                title: "Fout!",
                message: "We kunnen niet bepalen of er momenteel een IJskar in jouw buurt is! Probeer het later nog eens aub.",
                theme: "error",
                btn1Text: "Ok",
                btn1Theme: "primary",
                response: () => { store.dispatch(clearDialog()); },
            }));
        });
    }

    handleAvailableNearby() {
        store.dispatch(setAvailableNearby(true));
        // const address = (localStorage.getItem('straat') || "")+" "+(localStorage.getItem('nr') || "")+", "+(localStorage.getItem('postcode') || "")+" "+(localStorage.getItem('gemeente') || "")+", "+(localStorage.getItem('land') || "");
        const address = (localStorage.getItem('adres') || "");
        store.dispatch(setDialog({
            title: "Hoera!",
            message: "Er is een ijskar in de buurt van <br /><b>\""+address+"\"</b><br /><br />Wil je hem laten weten dat jullie een ijsje willen?",
            btn1Text: "Oproep plaatsen!",
            btn1Theme: "primary",
            btn1Title: "roep",
            btn2Text: "Adres wijzigen",
            btn2Title: "adres",
            btn3Text: "Annuleer",
            btn3Title: "annuleer",
            response: (response) => { this.handleAvailableResponse(response);},
        }));
    }

    handleNoAvailableNearby() {
        store.dispatch(setAvailableNearby(false));
        // const address = (localStorage.getItem('straat') || "")+" "+(localStorage.getItem('nr') || "")+", "+(localStorage.getItem('postcode') || "")+" "+(localStorage.getItem('gemeente') || "")+", "+(localStorage.getItem('land') || "");
        const address = (localStorage.getItem('adres') || "");
        store.dispatch(setDialog({
            title: "Helaas!",
            message: "Er is geen beschikbare ijskar in de buurt van <br /><b>\""+address+"\"</b><br /><br />Wil je toch een oproep plaatsen? Dan kan er misschien later op de dag nog een ijskar bij jullie langskomen.",
            theme: "error",
            btn1Text: "Toch een oproep plaatsen",
            btn1Theme: "primary",
            btn1Title: "roep",
            btn2Text: "Adres wijzigen",
            btn2Title: "adres",
            btn3Text: "Annuleer",
            btn3Title: "annuleer",
            response: (response) => { this.handleAvailableResponse(response);},
        }));
    }

    handleBlockTooFarAway() {
        store.dispatch(setAvailableNearby(false));
        // const address = (localStorage.getItem('straat') || "")+" "+(localStorage.getItem('nr') || "")+", "+(localStorage.getItem('postcode') || "")+" "+(localStorage.getItem('gemeente') || "")+", "+(localStorage.getItem('land') || "");
        const address = (localStorage.getItem('adres') || "");
        store.dispatch(setDialog({
            title: "Helaas!",
            message: "Er is geen beschikbare ijskar in de buurt van <br /><b>\""+address+"\"</b>",
            theme: "error",
            btn2Text: "Adres wijzigen",
            btn2Title: "adres",
            btn3Text: "Annuleer",
            btn3Title: "annuleer",
            response: (response) => { this.handleAvailableResponse(response);},
        }));
    }

    handleAvailableResponse(response) {
        if (response == "roep") {
            store.dispatch(clearDialog()); 
            store.dispatch(setLegitCall());
            this.setState({
                redirectToCall: true,
            });
        } else if (response == "adres") {
            store.dispatch(clearDialog()); 
            this.setState({
                redirectToAddress: true,
            });
        } else if (response == "annuleer") {
            store.dispatch(clearDialog()); 
        }
    }
    
    render() {
        const buttonSrc = (this.state.oproepGeplaatst ? 'images\\tracker\\icon_button_notavailable.png' : 'images\\tracker\\icon_button.png');
        const buttonTextSrc = 'images\\tracker\\icon_roep.png';

        if (this.state.redirectToCall === true) {
            return <Redirect to='/call' />
        }
        if (this.state.redirectToAddress === true) {
            return <Redirect to='/address' />
        }

        return (
            <div>
                <img src={buttonSrc} alt='Roep de IJskar!' className='iconhand trackerButton' onClick={(event) => {event.stopPropagation(); this.handleClick();}}/>
                <img src={buttonTextSrc} alt='Roep de IJskar!' className='iconhand trackerButtonText' onClick={(event) => {event.stopPropagation(); this.handleClick();}}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        makeCallOnOpen: state.tracker.makeCallOnOpen ?? false,
    }
}

export default connect(mapStateToProps)(CombinedButton);